const riot = require('riot');

riot.tag2('module-header', '<div class="f fm fbw py12"> <div class="hover-opacity-70 cursor-pointer" onclick="{scrollToTop}"><img class="block h55 object-fit-contain" src="/images/logo.png" alt=""></div> <div class="l-show f fm"> <div><a class="hover-opacity-70 hover-text-decoration-underline fs18 mr30 p10" onclick="{goAnchor}" each="{item in menu}" href="/">{item.label}</a></div> <div class="hover-opacity-70 bg-primary circle p12 mr16 mr0-last" each="{icon in icons}"><i class="fs32 text-white icon-{icon.label}"></i></div> </div> <div class="l-hide hover-opacity-70 bg-primary rounded-8 p15 cursor-pointer" onclick="{onOpenMenu}"><i class="icon-menu text-white fs30"></i></div> </div>', '', '', function(opts) {
    this.onOpenMenu = () => {
      spat.modal.open('modal-menu', {articles: this.opts.articles});
    };

    this.menu =[
      {label: 'Policy'},
      {label: 'Mission'},
      {label: 'Blog'},
      {label: 'Company'},
      {label: 'Contact'},
    ];

    this.getHeaderHeight = ()=> {
      return this.root.offsetHeight;
    }
    this.goAnchor = (e) => {
      e.preventDefault();
      if(this.opts.articles){
        spat.router.push('/');
      };
      app.utils.scrollByQuery(`#${e.item.item.label.toLowerCase()}`, this.getHeaderHeight());
    };

    this.scrollToTop = (e) => {
      if(this.opts.articles){
          spat.router.push('/');
        };
      app.utils.scrollToTOP();
    };
});