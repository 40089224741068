import $ from 'jquery';
global['$'] = $

import dayjs from 'dayjs';
global['dayjs'] = dayjs

import firebase from '~/plugins/firebase.js';
global['firebase'] = firebase

import app from '~/scripts/app.js';
global['app'] = app

import '~/styles/main.less';
