import client from '.spat/modules/client';

// client.showSSR();

firebase.auth().checkAuth().then((user) => {
  client.start();
  (() => {
    var snycMedia = () => {
      var media = getComputedStyle(document.documentElement).getPropertyValue('--media');
      app.media = media.trim();
      riot.update();
    };
  
    window.addEventListener('resize', () => {
      snycMedia();
    });
  
    snycMedia();
  })();
});
