
export default {
  '/': {
    tag: 'page-index',
  },
  '/articles': {
    tag: 'page-articles',
  },
};

