const riot = require('riot');

riot.tag2('modal-menu', '<div class="s-full bg-primary p16" ref="modal"> <div class="f fm fbw mb60"><img class="block object-fit-contain h47" src="/images/logo_white.png" alt=""> <div class="bg-white f fh s60 rounded-8" onclick="{close}"><i class="icon-close f fs30 text-primary"></i></div> </div> <div class="text-center text-white fs26 mb30"><a class="block mb20 mb0-last py4" each="{list in lists}" onclick="{goAnchor}" href="/">{list}</a></div> </div>', '', 'spat-animation="right"', function(opts) {
    this.lists = ['Concept', 'Policy', 'Mission', 'Blog', 'Company', 'Contact'];

    this.goAnchor = (e) => {
      e.preventDefault();
      this.close();
      if(this.opts.articles){
        spat.router.push('/');
      };
      app.utils.scrollByQuery(`#${e.item.list.toLowerCase()}`);
    };
});