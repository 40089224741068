const riot = require('riot');

riot.tag2('page-error', '<div class="s-full f fh"> <div class="text-center"> <h2 class="fs128 mb16">{statusCode}</h2> <p class="fs24 mb32">{errorMassage}</p><a class="text-link rounded-full" href="/">to TOP</a> </div> </div>', 'page-error,[data-is="page-error"]{display:block}', '', function(opts) {
    this.preload = ({req, res}) => {
      return {
        statusCode: res.statusCode,
        errorMassage: res.error ? res.error.message : 'Error',
      };
    };

    this.head = () => {
      return {
        title: 'Hello, spat!',
      };
    };
});