const riot = require('riot');

riot.tag2('spat-nav', '<div class="h-full" ref="pages"></div>', 'spat-nav,[data-is="spat-nav"]{display:block;height:100%} spat-nav .spat-page,[data-is="spat-nav"] .spat-page{height:100%}', '', function(opts) {
    this.cachedPages = {};

    this.goto = async ({route, req, res, ssr=true}) => {
      var tagName = '';
      var cached = false;

      if (typeof route.tag === 'function') {
        tagName = await route.tag({req, res});
      }
      else {
        tagName = route.tag;
      }

      this.trigger('pagechange');

      var tempPageTag = this.currentPageTag;

      if (spat.isBrowser && tempPageTag) {
        tempPageTag.trigger('hide', {
          req, res
        });
        tempPageTag.root.style.display = 'none';
      }

      var currentPageTag = this.getCachedPage(req.url);

      if (currentPageTag) {
        currentPageTag.root.style.display = '';
        cached = true;
      }
      else {
        var element = document.createElement('div');
        element.setAttribute('data-is', tagName);
        element.setAttribute('class', 'spat-page');
        element.setAttribute('cache-key', req.url);
        currentPageTag = riot.mount(element, tagName)[0];
        this.refs.pages.appendChild(element);
      }

      this.currentPageTag = currentPageTag;

      try {

        if (ssr) {
          await this.preload(currentPageTag, {
            req, res, cached,
          });

          if (res.statusCode === 301 || res.statusCode === 302) {
            currentPageTag.root.style.display = 'none';
            return ;
          }
        }

        if (this.currentPageTag === currentPageTag) {

          this._head = this.setupHead(currentPageTag);
          this.trigger('pagechanged', {});
        }

        else {

          currentPageTag.root.style.display = 'none';
        }
      }
      catch(e) {

        currentPageTag.root.style.display = 'none';

        console.error(e);

        res.error = e;

        var errorRoute = {
          tag: 'page-error'
        };
        await this.goto({route:errorRoute, req, res});
      }

      if (spat.isBrowser) {
        this.currentPageTag.trigger('show', {
          req, res, cached
        });
        this.currentPageTag.update();
      }

      if (spat.isBrowser && tempPageTag) {
        this.cachePageTag(tempPageTag);
      }
    };

    this.cachePageTag = (tag) => {
      this.cachedPages[tag.opts.cacheKey] = tag;
    };

    this.getCachedPage = (cacheKey) => {
      return this.cachedPages[cacheKey];
    };

    this.preload = async (tag, opts) => {
      if (tag.preload) {
        var data = await tag.preload(opts);
        Object.assign(tag, data);
        tag.update();
      }

      var tags = _.flatten(Object.values(tag.tags));

      if (tags.length <= 0) return ;

      var promises = tags.map(async (tag) => {
        return this.preload(tag, opts);
      });

      await Promise.all(promises);
    };

    this.setupHead = (tag) => {
      var head = {};
      spat.utils.extendDeep(head, spat.config.head);
      if (tag.head) {
        var data = tag.head();
        spat.utils.extendDeep(head, data);
      }

      return head;
    };

    this.getHead = () => {
      return this._head;
    };
});