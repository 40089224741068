const riot = require('riot');

riot.tag2('page-articles', '<div class="f fclm fbw h-full"> <div> <header class="fixed t0 l0 r0 bg-white z1"> <div class="container s-px16" data-is="module-header" articles="{true}"></div> </header> <main class="pt84 animation-appear" if="{notes.length}"><img class="block w-full object-fit-cover mb73 l-show" src="/images/dummy/article_top_pc.png" alt=""><img class="block w-full object-fit-cover mb73 l-hide" src="/images/dummy/article_top_sp.png" alt=""> <div class="container text-primary lh12 mb40 s-px16 s-text-center"> <div class="fs30 bold mb5">Blog</div> <div class="fs24">スタッフブログ</div> </div> <div class="s-hide container f"> <div class="container-730 mr32"> <div class="row mxn8"> <div class="col6 px8 mb56 s-col12" each="{item in notes}"> <div data-is="item-article" item="{item}"></div> </div> </div> <div class="f fh border-top border-top-dotted p16 mb32" if="{is_last_page === false}"> <button class="border-bottom p8" onclick="{fetchNotes}">もっと見る</button> </div> </div> <div class="w-full"> <div class="border-top border-base mb18"></div> <div class="mb25" data-is="module-writers" item="{writers}"></div> </div> </div> <div class="s-show px16"> <div class="border-bottom-dotted pb50 mb32" data-is="item-article" each="{item in notes}" item="{item}"></div> <div class="f fh p16 mb32" if="{is_last_page === false}"> <button class="border-bottom p8" onclick="{fetchNotes}">もっと見る</button> </div> <div class="mb42" data-is="module-writers" item="{writers}"></div> </div> </main> </div> <footer> <div data-is="module-footer"></div> </footer> </div>', '', '', function(opts) {
    this.categories = [
      {label: 'お知らせ', link: '/'},
      {label: 'コラム', link: '/'},
      {label: '求人情報', link: '/'},
      {label: 'その他', link: '/'},
    ];
    this.writers = [
      {label: '全て', link: '/articles'},
      {label: '東側 亜紀', link: '/articles?creator=higashigawa'},
    ];
    this.recommends = [
      {image: '/images/dummy/article_single.png', title: 'タイトルタイトル', created_at: 1615798403744, link: '/'},
      {image: '/images/dummy/article_single.png', title: 'タイトルタイトル', created_at: 1615798403744, link: '/'},
      {image: '/images/dummy/article_single.png', title: 'タイトルタイトル', created_at: 1615798403744, link: '/'},
      {image: '/images/dummy/article_single.png', title: 'タイトルタイトル', created_at: 1615798403744, link: '/'},
      {image: '/images/dummy/article_single.png', title: 'タイトルタイトル', created_at: 1615798403744, link: '/'},
    ];

    this.on('show', async ({req, res}) => {
      this.notes = [];
      this.page = 1;
      this.is_last_page = false;
      this.creator = req.query.creator;
      this.fetchNotes();
    });

    this.fetchNotes = async () => {
      if (this.creator) {
        var {notes, is_last_page} = await app.utils.notes.creator({
          creator: this.creator,
          page: this.page++,
        });
      }
      else {
        var {notes, is_last_page} = await app.utils.notes.magazine({
          page: this.page++,
        });
      }
      this.notes.push(...notes);
      this.is_last_page = is_last_page;
      this.update();
    };
});