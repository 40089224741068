const riot = require('riot');

riot.tag2('spat-app', '<div class="h-full" data-is="spat-nav"></div> <div data-is="spat-modal"></div> <div data-is="spat-toast"></div>', 'spat-app,[data-is="spat-app"]{display:block;height:100%} spat-app .spat-page,[data-is="spat-app"] .spat-page{height:100%}', '', function(opts) {
    this.title = 'Hello, spat with parcel!';
    this.cachedPages = {};

    this.on('mount', async () => {
      this.navTag = this.tags['spat-nav'];
      this.modalTag = this.tags['spat-modal'];
      this.toastTag = this.tags['spat-toast'];
    });

    this.triggerWithChildren = (tag, event, opts) => {
      tag.trigger(event, opts);

      var tags = _.flatten(Object.values(tag.tags));
      tags.forEach(tag => this.triggerWithChildren(tag, event, opts));
    };
});