
var utils = {
  wait(time) {
    return new Promise((resovle) => {
      setTimeout(resovle, time);
    });
  },

  notes: {
    async creator({creator='Prabil', page=1} = {}) {
      var res = await fetch(`/api/notes?creator=${creator}&page=${page}`);
      var data = await res.json();
      var notes = data.contents.map((item) => {
        return {
          title: item.name,
          description: item.body,
          link: item.noteUrl,
          published_at: item.publishAt,
          image: item.eyecatch,
          user: {
            icon: item.user.userProfileImagePath,
            name: item.user.nickname || item.user.name,
          }
        };
      });
  
      return {
        notes: notes,
        is_last_page: data.isLastPage,
      };
    },
    async magazine({magazine='m499fbd262291', page=1} = {}) {
      var res = await fetch(`/api/notes?magazine=${magazine}&page=${page}`);
      var data = await res.json();
      
      var notes = data.section.contents.map((item) => {
        return {
          title: item.name,
          description: item.body,
          link: `https://note.com/${item.user.urlname}/n/${item.key}`,
          published_at: item.publish_at,
          image: item.eyecatch,
          user: {
            icon: item.user.profile_image_path,
            name: item.user.nickname,
          }
        };
      });
  
      return {
        notes: notes,
        is_last_page: data.section.is_last_page,
      };
    },
  },

  scrollToElement(element, offset) {
    // その要素までアニメーションスクロール
    $('html,body').animate({
      scrollTop: element.offsetTop - offset,
    }, 256);
  },

  // query にマッチする要素の場所までスクロール
  scrollByQuery(query, offset) {
    if (!offset) {
      // SPのヘッダーの高さ分を考慮
      offset = 84;
    }
    var element = document.querySelector(query);
    // query にヒットするタグがあった場合
    if (element) {
      // その要素までスクロール
      this.scrollToElement(element, offset);
    }
  },
  
  // topにスクロールする
  scrollToTOP(body = 'html,body') {
    $(body).animate({
      scrollTop: 0,
    }, 256);
  },
};

export default utils;

