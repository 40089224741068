const riot = require('riot');

riot.tag2('spat-toast', '<div class="f flex-column" ref="items"></div>', 'spat-toast,[data-is="spat-toast"]{display:block;position:fixed;right:0px;bottom:0px;z-index:9999;pointer-events:none;padding:16px} spat-toast [data-is=spat-toast-item],[data-is="spat-toast"] [data-is=spat-toast-item]{margin-top:8px}', '', function(opts) {
    this.message = (text, timeout) => {
      var element = document.createElement('div');
      this.refs.items.appendChild(element);

      var tag = riot.mount(element, 'spat-toast-item', {
        text: text || 'Hello, Spat.js!',
        timeout: timeout,
      })[0];

      tag.close = function() {
        tag.unmount();
      };
    };

});
riot.tag2('spat-toast-item', '<span>{opts.text}</span>', 'spat-toast-item,[data-is="spat-toast-item"]{display:flex;padding:8px 20px;background-color:hsl(0,0%,50%);color:white;border-radius:3px;animation:toast-appear 500ms} spat-toast-item.disappear,[data-is="spat-toast-item"].disappear{animation:toast-disappear 500ms}@keyframes toast-appear{ 0%{transform:translateY(40px);opacity:0} 100%{transform:translateY(0);opacity:1}}@keyframes toast-disappear{ 0%{transform:translateY(0);opacity:1} 100%{transform:translateY(-40px);opacity:0}}', '', function(opts) {
    var timeout = opts.timeout || 2000;

    this.on('mount', () => {
      setTimeout(() => {
        this.root.classList.add('disappear');
        this.root.addEventListener('animationend', () => {
          this.close();
        });
      }, timeout);
    });
});