const riot = require('riot');

riot.tag2('spat-modal', '', 'spat-modal,[data-is="spat-modal"]{transform:translate3d(0, 0, 0);position:fixed;top:0;right:0;bottom:0;left:0;display:block;z-index:9999} spat-modal .modal-content,[data-is="spat-modal"] .modal-content{position:fixed;top:0;right:0;bottom:0;left:0}@keyframes modal-fade-in{ 0%{opacity:0} 100%{opacity:1}}@keyframes modal-fade-out{ 0%{opacity:1} 100%{opacity:0}}@keyframes modal-push-in{ 0%{transform:translateY(200px);opacity:0} 100%{transform:translateY(0);opacity:1}}@keyframes modal-push-out{ 0%{transform:translateY(0);opacity:1} 100%{transform:translateY(200px);opacity:0}}@keyframes modal-left-in{ 0%{transform:translateX(-200px);opacity:0} 100%{transform:translateX(0);opacity:1}}@keyframes modal-left-out{ 0%{transform:translateX(0);opacity:1} 100%{transform:translateX(-200px);opacity:0}}@keyframes modal-right-in{ 0%{transform:translateX(200px);opacity:0} 100%{transform:translateX(0);opacity:1}}@keyframes modal-right-out{ 0%{transform:translateX(0);opacity:1} 100%{transform:translateX(200px);opacity:0}}', 'show="{visible}"', function(opts) {
    this.open = (tagName, options) => {
      var _options = Object.assign({
        dismissible: true,
      }, options);

      var element = document.createElement('div');
      this.root.appendChild(element);

      var modalTag = riot.mount(element, tagName, _options)[0];
      element.classList.add('modal-content');

      var animation = element.getAttribute('spat-animation') || 'push';
      modalTag.refs.modal.style.animation = `modal-${animation}-in 256ms`;

      modalTag.promise = new Promise((resolve) => {
        modalTag.close = async () => {

          if (modalTag.isClose) return ;

          modalTag.isClose = true;
          var value = modalTag.value;

          await this.close(modalTag);
          resolve(value);

          return value;
        };
        modalTag.waitClose = () => {
          return modalTag.promise;
        };
        modalTag.update();
      });

      if (_options.dismissible) {
        modalTag.root.addEventListener('click', (e) => {

          if (e.currentTarget === e.target) {
            modalTag.close();
          }
        });
      }

      this.update({visible: true});

      return modalTag;
    };

    this.close = (modalTag) => {
      return new Promise(resolve => {

        var animation = modalTag.root.getAttribute('spat-animation') || 'push';
        modalTag.refs.modal.style.animation = `modal-${animation}-out 128ms forwards`;

        modalTag.refs.modal.addEventListener('animationend', (e) => {
          modalTag.unmount();

          if (this.root.childNodes.length <= 0) {
            this.update({visible: false});
          }

          resolve();
        });
      });
    };

    this.alert = (message, title, options) => {

      var templates = riot.util.templates;
      var template = templates['alert'] || templates['modal-alert'] || templates['spat-modal-alert'];

      var _options = {message, title, ...options};
      var modal = this.open(template.name, _options);

      return modal.waitClose();
    };

    this.confirm = (message, title, options) => {

      var templates = riot.util.templates;
      var template = templates['confirm'] || templates['modal-confirm'] || templates['spat-modal-confirm'];

      var _options = {message, title, ...options};
      var modal = this.open(template.name, _options);

      return modal.waitClose();
    };

    this.prompt = (message, title, options) => {

      var templates = riot.util.templates;
      var template = templates['prompt'] || templates['modal-prompt'] || templates['spat-modal-prompt'];

      var _options = {message, title, ...options};
      var modal = this.open(template.name, _options);

      return modal.waitClose();
    };

    this.indicator = (options) => {

      var templates = riot.util.templates;
      var template = templates['indicator'] || templates['modal-indicator'] || templates['spat-modal-indicator'];

      var _options = {...options};

      _options.dismissible = false;

      var modal = this.open(template.name, _options);

      return modal;
    };

});
riot.tag2('spat-modal-alert', '<div class="rounded-8" ref="modal"> <div class="p16 border"> <div class="bold fs16 text-center mb8" if="{opts.title}">{opts.title}</div> <p class="text-center white-space-pre-wrap word-break-all">{opts.message}</p> </div> <div class="w-full h44"> <button class="s-full" onclick="{close}">OK</button> </div> </div>', 'spat-modal-alert,[data-is="spat-modal-alert"]{background-color:rgba(0,0,0,0.5)} spat-modal-alert [ref=\'modal\'],[data-is="spat-modal-alert"] [ref=\'modal\']{background-color:rgba(255,255,255,0.9);max-width:300px;width:100%}', 'class="f fh" spat-animation="push"', function(opts) {
    this.on('mount', () => {

    });

});
riot.tag2('spat-modal-confirm', '<div class="rounded-8" ref="modal"> <div class="p16 border"> <div class="bold fs16 text-center mb8" if="{opts.title}">{opts.title}</div> <p class="text-center">{opts.message}</p> </div> <div class="w-full h44 f"> <button class="s-full border-right" onclick="{cancel}">Cancel</button> <button class="s-full bold" onclick="{ok}">OK</button> </div> </div>', 'spat-modal-confirm,[data-is="spat-modal-confirm"]{background-color:rgba(0,0,0,0.5)} spat-modal-confirm [ref=\'modal\'],[data-is="spat-modal-confirm"] [ref=\'modal\']{background-color:rgba(255,255,255,0.9);max-width:300px;width:100%}', 'class="f fh" spat-animation="push"', function(opts) {
    this.on('mount', () => {

      this.value = false;
    });

    this.ok = () => {
      this.value = true;
      this.close();
    };
    this.cancel = () => {
      this.value = false;
      this.close();
    };

});
riot.tag2('spat-modal-prompt', '<div class="rounded-8" ref="modal"> <div class="p16 border"> <div class="bold fs16 text-center mb8" if="{opts.title}">{opts.title}</div> <p class="text-center mb16">{opts.message}</p> <div> <input class="bg-white w-full fs15 border px8 py4" ref="input" type="text" autofocus> </div> </div> <div class="w-full h44 f"> <button class="s-full border-right" onclick="{cancel}">Cancel</button> <button class="s-full bold" onclick="{ok}">OK</button> </div> </div>', 'spat-modal-prompt,[data-is="spat-modal-prompt"]{background-color:rgba(0,0,0,0.5)} spat-modal-prompt [ref=\'modal\'],[data-is="spat-modal-prompt"] [ref=\'modal\']{background-color:rgba(255,255,255,0.9);max-width:300px;width:100%}', 'class="f fh" spat-animation="push"', function(opts) {
    this.on('mount', () => {

      this.value = '';

      this.refs.input.focus();
    });

    this.ok = () => {
      this.value = this.refs.input.value;
      this.close();
    };
    this.cancel = () => {
      this.value = '';
      this.close();
    };

});
riot.tag2('spat-modal-indicator', '<div ref="modal"> <div class="fs24 text-white">...</div> </div>', 'spat-modal-indicator,[data-is="spat-modal-indicator"]{background-color:rgba(0,0,0,0.75)}', 'class="f fh" spat-animation="fade"', function(opts) {
    this.on('mount', () => {
    });
});